































































import { Component, Mixins } from 'vue-property-decorator';
import IconDialog from '../../elements/com-icon-dialog.vue';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';

/**
 * input的配置项
 */
@Component({
  name: 'InputConfig',
  components: {
    IconDialog
  }
})
export default class extends Mixins(ElePropertiesMixins) {
  iconDialogVisible_suffix: boolean = false;
  iconDialogVisible_prefix: boolean = false;
  handlerSuffixSelectIcon() {
    this.iconDialogVisible_suffix = true;
  }
  handlerPrefixSelectIcon() {
    this.iconDialogVisible_prefix = true;
  }
}
