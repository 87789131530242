var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "input",
          expression: "widget.compType === 'input'",
        },
      ],
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_component_name") } },
        [
          _c("lang-input", {
            staticClass: "input",
            attrs: { placeholder: _vm.$t("lang_please_enter"), clearable: "" },
            model: {
              value: _vm.widget.compName,
              callback: function ($$v) {
                _vm.$set(_vm.widget, "compName", $$v)
              },
              expression: "widget.compName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_component_code") } },
        [
          _c("el-input", {
            staticClass: "input",
            attrs: { placeholder: _vm.$t("lang_please_enter"), clearable: "" },
            model: {
              value: _vm.widget.compCode,
              callback: function ($$v) {
                _vm.$set(_vm.widget, "compCode", $$v)
              },
              expression: "widget.compCode",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_display_label") } },
        [
          _c("el-switch", {
            model: {
              value: _vm.widget.properties.showLabel,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "showLabel", $$v)
              },
              expression: "widget.properties.showLabel",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_label_width") } },
        [
          _c("el-input-number", {
            attrs: { min: 0 },
            model: {
              value: _vm.widget.properties.labelWidth,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "labelWidth", $$v)
              },
              expression: "widget.properties.labelWidth",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_label_position") } },
        [
          _c(
            "el-select",
            {
              model: {
                value: _vm.widget.properties.labelPosition,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelPosition", $$v)
                },
                expression: "widget.properties.labelPosition",
              },
            },
            [
              _c("el-option", {
                attrs: { label: _vm.$t("lang_align_left"), value: "left" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("lang_center"), value: "center" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("lang_align_right"), value: "right" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_label_border") } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.widget.properties.cellBorder,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "cellBorder", $$v)
                },
                expression: "widget.properties.cellBorder",
              },
            },
            [
              _c("el-radio", { attrs: { label: true } }, [
                _vm._v(_vm._s(_vm.$t("lang_display"))),
              ]),
              _c("el-radio", { attrs: { label: false } }, [
                _vm._v(_vm._s(_vm.$t("lang_hide"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_input_box_width") } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 100 },
            model: {
              value: _vm.widget.properties.width,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "width", $$v)
              },
              expression: "widget.properties.width",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_text_size") } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 30 },
            model: {
              value: _vm.widget.properties.fontSize,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "fontSize", $$v)
              },
              expression: "widget.properties.fontSize",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_text_color") } },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.widget.properties.fontColor,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "fontColor", $$v)
              },
              expression: "widget.properties.fontColor",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_prompt") } },
        [
          _c("lang-input", {
            staticClass: "input",
            attrs: { placeholder: _vm.$t("lang_please_enter"), clearable: "" },
            model: {
              value: _vm.widget.properties.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "placeholder", $$v)
              },
              expression: "widget.properties.placeholder",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_front_Icon") } },
        [
          _c(
            "el-input",
            {
              attrs: {
                placeholder: _vm.$t("lang_please_select_Icon"),
                readonly: "",
              },
              model: {
                value: _vm.widget.properties.prefixIcon,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "prefixIcon", $$v)
                },
                expression: "widget.properties.prefixIcon",
              },
            },
            [
              _c("template", { slot: "append" }, [
                _c("i", {
                  staticClass: "el-icon-picture",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.handlerPrefixSelectIcon },
                }),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_post_Icon") } },
        [
          _c(
            "el-input",
            {
              attrs: {
                placeholder: _vm.$t("lang_please_select_Icon"),
                readonly: "",
              },
              model: {
                value: _vm.widget.properties.suffixIcon,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "suffixIcon", $$v)
                },
                expression: "widget.properties.suffixIcon",
              },
            },
            [
              _c("template", { slot: "append" }, [
                _c("i", {
                  staticClass: "el-icon-picture",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.handlerSuffixSelectIcon },
                }),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("icon-dialog", {
        attrs: { visible: _vm.iconDialogVisible_suffix },
        on: {
          "update:visible": function ($event) {
            _vm.iconDialogVisible_suffix = $event
          },
        },
        model: {
          value: _vm.widget.properties.suffixIcon,
          callback: function ($$v) {
            _vm.$set(_vm.widget.properties, "suffixIcon", $$v)
          },
          expression: "widget.properties.suffixIcon",
        },
      }),
      _c("icon-dialog", {
        attrs: { visible: _vm.iconDialogVisible_prefix },
        on: {
          "update:visible": function ($event) {
            _vm.iconDialogVisible_prefix = $event
          },
        },
        model: {
          value: _vm.widget.properties.prefixIcon,
          callback: function ($$v) {
            _vm.$set(_vm.widget.properties, "prefixIcon", $$v)
          },
          expression: "widget.properties.prefixIcon",
        },
      }),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_operation_status") } },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("lang_please_select"),
                clearable: "",
              },
              model: {
                value: _vm.widget.properties.operation,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "operation", $$v)
                },
                expression: "widget.properties.operation",
              },
            },
            _vm._l(_vm.statusList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.label, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }